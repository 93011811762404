@font-face {
    font-family: 'LG Smart Bold';
    font-display: swap;
    src: local("☺"),
        url('./webfonts/LG\ Smart\ Bold.woff2') format("woff2");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'LG Smart SemiBold';
    font-display: swap;
    src: local("☺"),
        url('./webfonts/LG\ Smart\ SemiBold.woff2') format("woff2");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'LG Smart Regular';
    font-display: swap;
    src: local("☺"),
        url('./webfonts/LG\ Smart\ Regular.woff2') format("woff2");
    font-weight: 400;
    font-style: normal
}

.w-10 {
    width: 5% !important;
}

.background, html {
    background-color: #f0ece4 !important;
    /* border: 1px solid #e4e4e4 !important; */
    height: auto;
}

input[type=file],
input[type=date],
input[type=email],
input[type=number],
input[type=password],
input[type=tel],
input[type=text] {
    background: #fff;
    border: 1px solid #333;
    border-radius: 0;
    color: #333;
    font-family: 'LG Smart Regular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 5px;
}

input[type=checkbox]{
    width: 22px;
    height: 22px;
}

textarea {
    border: 1px solid #333 !important;
    border-radius: 0 !important;
    color: #333 !important;
    font-size: 13px !important;
    padding: 0 10px !important;
}

h2 {
    font-family: 'LG Smart SemiBold', sans-serif;
    font-size: 1.5rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 1.75rem !important;
    letter-spacing: 0.009rem !important;
}

label {
    font-size: 13px;
    line-height: 1;
    color: #333;
    font-family: "LG Smart SemiBold", sans-serif;
    font-weight: 700;
}

.parrafo {
    font-family: 'LG Smart Regular', sans-serif;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 109.09%;
}

.btn {
    display: inline-block;
    min-width: 40px;
    padding: 6px 10px;
    border-radius: 0px !important;
    border: 1px solid transparent;
    font-family: 'LG Smart Bold', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    cursor: pointer;
}

.btn.btn-danger {
    color: #fff !important;
    border-color: #a50034 !important;
    background: #a50034 !important;
}

.btn-outline-danger {
    color: #a50034 !important;
    border-color: #a50034 !important;
}

.btn-outline-danger:hover {
    color: #fff !important;
    background: #a50034 !important;
}


.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn.btn-flat {
    font-weight: 700 !important;
}

.input-group-text {
    background: #a50034 !important;
    /*background-color: #808080 !important;
    */
    color: #ffffff !important;
    border: 1px solid #333 !important;
    border-radius: 0 !important;
}

.react-select-container {
    border: 1px solid #000 !important;
    color: #000 !important;
    border-radius: 0 !important;
}

.textred {
    color: #a50034;
}

/* a {
    color: #212529 !important;
}

a:hover {
    color: #a50034;
} */

.popover-container {
    position: relative;
    display: inline-block;
}

.popover-trigger {
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.popover-content {
    position: absolute;
    top: -100%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    text-decoration: none;
    z-index: 1000;   
    word-wrap: break-word;
    width: 20vw;
}
.popover-content a{
    color: #000000;
    text-decoration: none;
}
.popover-content a:hover{
    color: #000000;
    text-decoration: none;
}